import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  width: 60%;
  margin: 0 auto;
  padding-top: 40px;
`;

const Career = ({ t }) => (
  <Wrapper>
    <p style={{ marginTop: '50px' }}>{t('send-cv')}</p>
    <div>
      <h3>{t('backend-title')}</h3>
      <h5>{t('base')}</h5>
      <div style={{ textAlign: 'left', marginTop: '20px' }}>
        <h5>{t('responsibility')}</h5>
        <ul>
          <li>{t('backend-resp-1')}</li>
          <li>{t('backend-resp-2')}</li>
        </ul>
        <h5>{t('experience')}</h5>
        <ul>
          <li>{t('backend-exp-1')}</li>
          <li>{t('backend-exp-2')}</li>
          <li>{t('backend-exp-3')}</li>
          <li>{t('backend-exp-4')}</li>
          <li>{t('backend-exp-5')}</li>
        </ul>
      </div>
    </div>
    <div>
      <h3>{t('frontend-title')}</h3>
      <h5>{t('base')}</h5>
      <div style={{ textAlign: 'left', marginTop: '20px' }}>
        <h5>{t('responsibility')}:</h5>
        <ul>
          <li>{t('frontend-resp-1')}</li>
          <li>{t('frontend-resp-2')}</li>
        </ul>
        <h5>{t('experience')}</h5>
        <ul>
          <li>{t('frontend-exp-1')}</li>
          <li>{t('frontend-exp-2')}</li>
          <li>{t('frontend-exp-3')}</li>
          <li>{t('frontend-exp-4')}</li>
        </ul>
      </div>
    </div>
  </Wrapper>
);

export default translate('career')(Career);
