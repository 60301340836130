import { Link } from 'gatsby';
import React from 'react';
import HoverImage from 'react-hover-image';
import logo from './ATPLogo.png';
import github from './github@2x.png';
import githubHover from './githubHover@2x.png';
import medium from './medium@2x.png';
import mediumHover from './MediumHover@2x.png';
import telegram from './telegram@2x.png';
import telegramHover from './TelegramHover@2x.png';
import twitter from './twitter@2x.png';
import twitterHover from './twitterHover@2x.png';
import wechat from './Wechat@2x.png';
import qrcode from './QRCode@2x.png';
import ReactTooltip from 'react-tooltip';

const Footer = ({ siteTitle }) => (
  <div
    style={{
      paddingTop: '20px',
      background: 'black',
      height: '130px'
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        paddingTop: 20
      }}
    >
      <Link
        to="/"
        style={{
          textDecoration: 'none',
          marginLeft: '1rem'
        }}
      >
        <img src={logo} alt="Atlas Protocol" style={{ width: 176 }} />
      </Link>
      <div style={{ float: 'right', position: 'relative' }}>
        <img
          src={wechat}
          style={{ width: '20px', marginRight: '15px', marginBottom: '0px' }}
          data-tip
          data-for="qrcode"
          data-event="hover click focus"
          alt="wechat"
        />

        <ReactTooltip id="qrcode" aria-haspopup="true" effect="solid">
          <img src={qrcode} alt="" />
        </ReactTooltip>

        <a href="https://github.com/AtlasProtocol" target="blank">
          <HoverImage
            src={github}
            hoverSrc={githubHover}
            style={{ width: '20px', marginRight: '15px', marginBottom: '0px' }}
          />
        </a>
        <a href="https://twitter.com/atlaspio" target="blank">
          <HoverImage
            src={twitter}
            hoverSrc={twitterHover}
            style={{ width: '20px', marginRight: '15px', marginBottom: '0px' }}
          />
        </a>
        <a href="https://medium.com/@atlasp" target="blank">
          <HoverImage
            src={medium}
            hoverSrc={mediumHover}
            style={{ width: '20px', marginRight: '15px', marginBottom: '0px' }}
          />
        </a>
        <a href="http://t.me/atlaspio" target="blank">
          <HoverImage
            src={telegram}
            hoverSrc={telegramHover}
            style={{ width: '20px', marginRight: '15px', marginBottom: '0px' }}
          />
        </a>

        <p
          style={{
            color: 'white',
            fontSize: '12px',
            textAlign: 'right',
            marginRight: '20px',
            marginBottom: '0px'
          }}
        >
          contact@atlasp.io
        </p>
        <p
          style={{
            color: 'white',
            fontSize: '12px',
            textAlign: 'right',
            marginRight: '20px',
            marginBottom: '0px'
          }}
        >
          Copyright © 2018 atlasp.io
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
