import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';
import Header from './header/airdropHeader';
import './layout.css';

const Layout = ({ children, t }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleAQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={t('title')}
          meta={[
            { name: 'description', content: t('description') },
            { name: 'keywords', content: t('keywords') }
          ]}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default translate('seo')(Layout);
