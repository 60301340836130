import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Career from '../components/career/career';
import Footer from '../components/footer/footer';
import Layout from '../components/layout';
import i18n from '../i18n';

const CareerPage = () => (
  <I18nextProvider i18n={i18n}>
    <Layout>
      <Career />
      <Footer />
    </Layout>
  </I18nextProvider>
);

export default CareerPage;
