import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Advisors from '../components/advisors/advisors';
import Features from '../components/features/feature';
import Footer from '../components/footer/footer';
import Layout from '../components/layout';
import Members from '../components/members/members';
import Roadmap from '../components/roadmap/roadmap';
import Slogan from '../components/slogan/slogan';
import i18n from '../i18n';

const IndexPage = () => (
  <I18nextProvider i18n={i18n}>
    <Layout>
      <Slogan />
      <Features />
      <Members />
      <Advisors />
      <Roadmap />
      <Footer />
    </Layout>
  </I18nextProvider>
);

export default IndexPage;
