import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #27272b;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  color: white;
`;

const TimelineWrapper = styled.div`
  &::before {
    content: ' ';
    display: block;
    width: 1px;
    height: 350px;
    background: #bebedd;
    position: absolute;
    top: 10px;
    left: 50%;
    @media (max-width: 550px) {
      height: 450px;
    }
  }

  width: 100%;
  height: 500px;
  position: relative;
`;

const TimelineItem = styled.div`
  &::before {
    content: ' ';
    display: block;
    background: ${props => (props.done === 'true' ? '#0040ff' : '#fff')};
    border: 0.01rem solid #27272b;
    position: absolute;
    width: 20px;
    top: 5px;
    height: 20px;
    left: calc(50% - 10px);
    transform: scale(0.8) rotate(-315deg);
  }

  margin-top: 30px;
  position: relative;
  text-align: ${props => props.position};
  top: 20px;
  padding-left: ${props => (props.position === 'left' ? '53%' : '0%')};
  padding-right: ${props => (props.position === 'right' ? '53%' : '0%')};
  font-size: 20px;

  @media (max-width: 550px) {
    font-size: 16px;
  }
`;

const Roadmap = ({ t }) => (
  <Wrapper>
    <h2>{t('roadmap')}</h2>
    <TimelineWrapper>
      <TimelineItem position="left" done="false">
        {t('roadmap5')}
      </TimelineItem>
      <TimelineItem position="right" done="false">
        {t('roadmap4')}
      </TimelineItem>
      <TimelineItem position="left" done="false">
        {t('roadmap3')}
      </TimelineItem>
      <TimelineItem position="right" done="true">
        {t('roadmap2')}
      </TimelineItem>
      <TimelineItem position="left" done="true">
        {t('roadmap1')}
      </TimelineItem>
    </TimelineWrapper>
  </Wrapper>
);

export default translate('index')(Roadmap);
