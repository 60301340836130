import { Link } from 'gatsby';
import React from 'react';
import { translate } from 'react-i18next';
import logo from './ATPLogo.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { lang: i18n.language };
    console.log(i18n.language);
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div
        style={{
          background: 'white',
          width: '100%',
          zIndex: 100,
        }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            paddingTop: 20,
            paddingRight: 20,
          }}>
          <Link
            to="/"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '1rem',
            }}>
            <img src={logo} alt="Atlas Protocol" style={{ marginBottom: '10px', width: '100px' }} />
          </Link>
          <div style={{ float: 'right', fontSize: '13px' }}>
            <Link
              to="/"
              style={{
                color: 'black',
                textDecoration: 'none',
                marginRight: '0.7rem',
              }}>
              {t('home')}
            </Link>
            {this.state.lang === 'zh-CN' || this.state.lang === 'cn' || this.state.lang === 'zh' ? (
              <a
                href="https://atlaspro.io"
                target="blank"
                style={{ color: '#000', marginRight: 10 }}>
                产品
              </a>
            ) : (
              <a
                href="https://atlaspro.io/en"
                target="blank"
                style={{ color: '#000', marginRight: 10 }}>
                Product
              </a>
            )}
            <a
              onClick={e => {
                e.preventDefault();
                i18n.changeLanguage('en');
                this.setState({ lang: 'en' });
              }}
              href="https://atlasp.io"
              style={
                this.state.lang !== 'en' && this.state.lang !== 'en-US'
                  ? { display: 'inline' }
                  : { display: 'none' }
              }>
              En
            </a>
            <a
              onClick={e => {
                e.preventDefault();
                i18n.changeLanguage('zh-CN');
                this.setState({ lang: 'zh-CN' });
              }}
              href="https://atlasp.io"
              style={
                this.state.lang === 'en' || this.state.lang === 'en-US'
                  ? { display: 'inline' }
                  : { display: 'none' }
              }>
              中
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('index')(Header);
