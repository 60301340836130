import React from 'react';
import { translate } from 'react-i18next';
import banner from './AtlasBannerLogo.svg';

const Slogan = ({ t }) => (
  <div
    style={{
      background: 'black',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '10px',
      color: 'white',
      height: '100vh'
    }}
  >
    <img
      src={banner}
      alt="atlas banner"
      style={{ width: '600px', marginBottom: '40px' }}
    />
    <h3
      style={{
        marginTop: 10,
        fontStyle: 'normal',
        fontSize: 20,
        marginBottom: 20,
        textAlign: 'center',
        letterSpacing: 2,
        fontWeight: 'normal'
      }}
    >
      {t('slogan')}
    </h3>
    <div>
      <a
        href="https://atlasp.io/docs/atlas-protocol-light-paper.pdf"
        style={{ color: 'white', letterSpacing: 1 }}
        target="blank"
      >
        {t('lightpaper')}
      </a>
      |
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdoNBe9VjamaNGVJ99KrOao4EZkdFfkH-lTuK-RZ8vTwUYejQ/viewform"
        style={{ color: 'white', letterSpacing: 1 }}
        target="blank"
      >
        {t('whitepaper')}
      </a>
    </div>
  </div>
);

export default translate('index')(Slogan);
