import React from 'react';
import { I18n } from 'react-i18next';
import styled from 'styled-components';
import interaction from './NewMarketingMInteractions@2x.png';
import targeting from './SmartUserTargeting@2x.png';
import protection from './UserDataProtection@2x.png';

const Wrapper = styled.div`
  background: rgb(30, 30, 33);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  color: white;
`;

const FeatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30;
  width: 60%;
  @media (max-width: 768px) {
    flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  }
`;

const FeatureDesc = styled.div`
  text-align: center;
  padding: 0 20px;
  flex: 1 1 0;
`;

const Features = () => (
  <I18n ns={['index']}>
    {t => (
      <Wrapper>
        <h2>{t('feature-overview')}</h2>
        <FeatureWrapper>
          <img src={targeting} alt="protection" style={{ width: '340px' }} />
          <FeatureDesc>
            <h3>{t('smart-user-targeting')}</h3>
            <p>{t('smart-user-targeting-desc')}</p>
          </FeatureDesc>
        </FeatureWrapper>
        <FeatureWrapper reverse>
          <FeatureDesc>
            <h3>{t('new-marketing-interactions')}</h3>
            <p>{t('new-marketing-interactions-desc')}</p>
          </FeatureDesc>
          <img src={interaction} alt="protection" style={{ width: '340px' }} />
        </FeatureWrapper>
        <FeatureWrapper>
          <img src={protection} alt="protection" style={{ width: '340px' }} />
          <FeatureDesc>
            <h3>{t('user-data-protection')}</h3>
            <p>{t('user-data-protection-desc')}</p>
          </FeatureDesc>
        </FeatureWrapper>
      </Wrapper>
    )}
  </I18n>
);

export default Features;
