import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';
import li from './ChengLi@2x.png';
import liu from './DuranLiu@2x.png';
import HoverImage from 'react-hover-image';
import linkedIn from './linkedin@2x.png';
import linkedInHover from './linkedinHover@2x.png';

const Wrapper = styled.div`
  background: #27272b;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  color: white;
`;
const MemberRow = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  width: 80%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Member = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MemberLeft = styled.div`
  text-align: center;
  margin-right: 10px;
  flex: 1;
`;

const MemberRight = styled.p`
  flex: 2;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
`;

const MemberName = styled.h4`
  margin-bottom: 5px;
`;

const MemberTitle = styled.p`
  margin-bottom: 5px;
  font-size: 13px;
`;

const Members = ({ t }) => (
  <Wrapper>
    <h2>{t('team-member')}</h2>
    <MemberRow>
      <Member>
        <MemberLeft>
          <img src={liu} alt="Duran Liu" style={{ width: 100 }} />
          <MemberName>{t('du')}</MemberName>
          <MemberTitle>{t('du-title')}</MemberTitle>
          <a href="https://www.linkedin.com/in/duranliu/" target="blank">
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </MemberLeft>
        <MemberRight>{t('du-desc')}</MemberRight>
      </Member>
      <Member>
        <MemberLeft>
          <img src={li} alt="Cheng Li" style={{ width: 100 }} />
          <MemberName>{t('li')}</MemberName>
          <MemberTitle>{t('li-title')}</MemberTitle>
          <a
            href="https://www.linkedin.com/in/hackingcheng/"
            target="blank"
            style={{ color: '#0040ff' }}
          >
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </MemberLeft>
        <MemberRight>{t('cheng-li-desc')}</MemberRight>
      </Member>
    </MemberRow>
  </Wrapper>
);

export default translate('index')(Members);
