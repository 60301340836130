import { Link } from 'gatsby';
import React from 'react';
import { translate } from 'react-i18next';
import logo from './ATPLogo.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { lang: i18n.language };
    console.log(i18n.language);
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div
        style={{
          background: 'white',
          width: '100%',
          zIndex: 100
        }}
      >
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            paddingTop: 20,
            paddingRight: 20
          }}
        >
          <Link
            to="/"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '1rem'
            }}
          >
            <img
              src={logo}
              alt="Atlas Protocol"
              style={{ marginBottom: '10px', width: '136px' }}
            />
          </Link>
          <div style={{ float: 'right', fontSize: '14px' }}>
            <Link
              to="/"
              style={{
                color: 'black',
                textDecoration: 'none',
                marginRight: '1rem'
              }}
            >
              返回官网
            </Link>
            {/* <a
              onClick={e => {
                e.preventDefault();
              }}
            >
              中文
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default translate('index')(Header);
