import React from 'react';
import { I18nextProvider } from 'react-i18next';
import Layout from '../components/airdropLayout';
import i18n from '../i18n';
import Particles from 'react-particles-js';

const SmartdropPage = () => (
  <I18nextProvider i18n={i18n}>
    <Layout>
      <Particles
        width="100%"
        height="100vh"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 868.0624057955
              }
            },
            color: {
              value: '#ecd018'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#fff'
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: 'img/github.svg',
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#ffffff',
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 5,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: false,
                mode: 'grab'
              },
              onclick: {
                enable: false,
                mode: 'push'
              },
              resize: false
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        }}
        style={{
          backgroundColor: '#252527',
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      />
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          clear: 'both',
          overflowY: 'scroll'
        }}
      >
        <iframe
          width="500"
          height="650"
          src="https://atp-airdrop.atlasp.io?campaignID=cbf2rgmnk9uhbm6hspks0"
          style={{
            marginTop: 50,
            borderWidth: 0,
            zIndex: 1000,
            position: 'relative'
          }}
        />
      </div>
    </Layout>
  </I18nextProvider>
);

export default SmartdropPage;
