import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';
import e from './Ellen@2x.png';
import hi from './Hitters@2x.png';
import j from './Jeremy@2x.png';
import p from './Pingzhong@2x.png';
import v from './Vilhelm@2x.png';
import HoverImage from 'react-hover-image';
import linkedIn from './linkedin@2x.png';
import linkedInHover from './linkedinHover@2x.png';

const Wrapper = styled.div`
  background: #1e1e21;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  color: white;
`;
const AdvisorRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  width: 80%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Advisor = styled.div`
  flex: 1;
  flex-direction: column
  display: flex;
  margin: 20px;
`;

const AdvisorTop = styled.div`
  text-align: center;
  margin-right: 10px;
  flex: 1;
`;

const AdvisorBottom = styled.p`
  flex: 2;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
`;

const AdvisorName = styled.h4`
  margin-bottom: 5px;
`;

const AdvisorTitle = styled.p`
  margin-bottom: 5px;
  font-size: 13px;
`;

const Advisors = ({ t }) => (
  <Wrapper>
    <h2>{t('advisors')}</h2>
    <AdvisorRow>
      <Advisor>
        <AdvisorTop>
          <img src={hi} alt="Cheng Li" style={{ width: 100 }} />
          <AdvisorName>{t('xu')}</AdvisorName>
          <AdvisorTitle>{t('xu-title')}</AdvisorTitle>
          <a
            href="https://www.linkedin.com/in/hitters/"
            target="blank"
            style={{ color: '#0040ff' }}
          >
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </AdvisorTop>
        <AdvisorBottom>{t('xu-desc')}</AdvisorBottom>
      </Advisor>
      <Advisor>
        <AdvisorTop>
          <img src={j} alt="" style={{ width: 100 }} />
          <AdvisorName>{t('zhou')}</AdvisorName>
          <AdvisorTitle>{t('zhou-title')}</AdvisorTitle>
          <a
            href="https://www.linkedin.com/in/jeremy-chau-57619611/"
            target="blank"
          >
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </AdvisorTop>
        <AdvisorBottom>{t('zhou-desc')}</AdvisorBottom>
      </Advisor>
      <Advisor>
        <AdvisorTop>
          <img src={e} alt="Duran Liu" style={{ width: 100 }} />
          <AdvisorName>{t('ellen')}</AdvisorName>
          <AdvisorTitle>{t('ellen-title')}</AdvisorTitle>
          <a
            href="https://www.linkedin.com/in/tingting-wang-b026a457/"
            target="blank"
          >
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </AdvisorTop>
        <AdvisorBottom>{t('ellen-desc')}</AdvisorBottom>
      </Advisor>
    </AdvisorRow>
    <AdvisorRow>
      <Advisor>
        <AdvisorTop>
          <img src={v} alt="Dr. Vilhelm Sjöberg  " style={{ width: 100 }} />
          <AdvisorName>Dr. Vilhelm Sjöberg</AdvisorName>
          <AdvisorTitle>{t('gu-title')}</AdvisorTitle>
          <a href="https://certik.org/" target="blank">
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </AdvisorTop>
        <AdvisorBottom>{t('gu-desc')}</AdvisorBottom>
      </Advisor>
      <Advisor>
        <AdvisorTop>
          <img src={p} alt="Duran Liu" style={{ width: 100 }} />
          <AdvisorName>{t('tang')}</AdvisorName>
          <AdvisorTitle>{t('tang-title')}</AdvisorTitle>
          <a
            href="https://www.linkedin.com/in/pingzhong-tang-615b479/"
            target="blank"
          >
            <HoverImage
              src={linkedIn}
              hoverSrc={linkedInHover}
              style={{ width: '12px' }}
            />
          </a>
        </AdvisorTop>
        <AdvisorBottom>{t('tang-desc')}</AdvisorBottom>
      </Advisor>
    </AdvisorRow>
  </Wrapper>
);

export default translate('index')(Advisors);
